import React from 'react';
import { Tabs, Input, Space, Button, message } from 'antd';
import './index.less';
import leftBg from '../../assets/img/_login/login_bg.png'
import { CloseOutlined } from '@ant-design/icons';
import { getSmsCode, goLogin } from '../../api/login'
import { passwordlogin } from '../../api/password'
import { getUserInfo, setUserInfoToLocal } from '../../api/user'
import { Link } from "react-router-dom"

const { TabPane } = Tabs


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNum: "",
      smsCode: "",
      smsValue: "获取验证码",
      password:""
    };
  }

  close() {
    console.log("关闭登录窗口")
  }
  componentDidMount() {

  }

  getSmsCode() {
    var reg = /^[1][0-9]{10}$/
    if (!reg.test(this.state.phoneNum)) {
      message.error({ content: '请输入正确的手机号!', duration: 2 })
      return false;
    }
    getSmsCode({ user_name: this.state.phoneNum }).then(res => {
      console.log(res.error_no, "/??????", res.error_message.split("秒")[0])
      let countDownTime = 59
      this.setState({
        smsValue: `已发送(${countDownTime + 1}s)`
      })
      if (res.error_no == 499) {
        message.error({ content: res.error_message, duration: 2 })
        countDownTime = parseInt(res.error_message.split("秒")[0])
        this.setState({
          smsValue: `已发送(${countDownTime}s)`
        })
      }
      let timer = setInterval(() => {
        if (countDownTime <= 0) {
          this.setState({
            smsValue: "获取验证码"
          })
          clearInterval(timer)
        } else {
          this.setState({
            smsValue: `已发送(${countDownTime--}s)`
          })
        }
      }, 1000)
    }, err => {
      console.log("ERR from catch", err)
    })

  }
  afterLogin(res){
    getUserInfo().then(res => {
      setUserInfoToLocal(res)
    })
    setTimeout(() => { //为了更新会员信息，不确定是否是前端问题，临时解决方案 //todo
      window.location.reload();
    }, 300);
  }
  passwordLogin(){
    console.log(10000,"passwordlogin",this.state.phoneNum,this.state.password)
    passwordlogin({
      user_name:this.state.phoneNum,
      password:this.state.password
    }).then(res=>{
      console.log(100212,res)
      localStorage.setItem("token", res.data.token)
      if(res.data.user_info.has_password == 0){
        this.props.handleDisplay(false,res.data.token)
      } else {
        this.props.handleDisplay(false)
        window.location.reload()
      }
      message.success({ content: '登录成功!', duration: 2 });
    })
  }
  
  submit() {
    goLogin({
      user_name: this.state.phoneNum,
      phone_code: this.state.smsCode
    }).then(res => {
      // 15335461058
      // let res = {"error_no":0,"error_message":"","data":{"token":"d3b9d642687374b72944cb3b8e23c05b","user_info":{"id":"9","user_name":"15335461058","password":null,"cellphone":"15335461058","level":"1","email":null,"avatar":null,"company":null,"industry":null,"job_name":null,"weixin_code":"","weixin_name":null,"source":"1","cancel_reason":null,"status":"1","last_login_time":"1611849798","level_start_time":"1610988303","level_end_time":"1642524303","follow_setting":"1","is_subscribe_oa":"0","subscription_setting":"1","create_time":"1609673540","has_password":0}}}
      //  // 15010195133
      // let res = {"error_no":0,"error_message":"","data":{"token":"43d41324c2107b316a0c2a91858571b2","user_info":{"id":"4","user_name":"15010195133","password":"3327e2a35606022313b680f2a49908d4","cellphone":"15010195133","level":"1","email":"1231@123.com","avatar":"http:\/\/thirdwx.qlogo.cn\/mmopen\/PiajxSqBRaEJS8UiaCHoPthZ01T0dZWQzQLOeTXCh6TmZcibdsj8bkLrVhYiaCbcPn8IHBWk5xZyvE1eWoMSLY6Ajw\/132","company":"123","industry":"","job_name":"321","weixin_code":"ooi_D5ncci4EHfxTmLNDZZKq_QZo","weixin_name":"吴建飞","source":"1","cancel_reason":null,"status":"1","last_login_time":"1612082616","level_start_time":"1609852734","level_end_time":"1641388734","follow_setting":"1","is_subscribe_oa":"0","subscription_setting":"1","create_time":"1609307457","has_password":1}}}
      console.log(1000998,res)
      localStorage.setItem("token", res.data.token)
      if(res.data.user_info.has_password == 0){
        this.props.handleDisplay(false,res.data.token)
      } else {
        this.props.handleDisplay(false)
      }
      message.success({ content: '登录成功!', duration: 2 });

      // this.afterLogin(res)
      
    })

    console.log("登录注册", this.state.smsCode, 999)
  }
  inputChange = (key, e) => {
    console.log(key, e.target.value)
    this.setState({
      [key]: e.target.value
    })
  }

  render() {
    return (
      <div className="login-wrapper">
        <div className="login">
          <div className="login-img">
            <img width='100%' src={leftBg} alt="" />
          </div>
          <div className="login-r">
            <CloseOutlined className='login-r-close' onClick={() => this.props.handleDisplay(false)} />

            <Tabs defaultActiveKey="1" centered>
              <TabPane tab="验证码登录" key="1">
                <Input value={this.state.phoneNum} onChange={this.inputChange.bind(this, "phoneNum")} size='large' placeholder="请输入手机号" maxLength='11' allowClear className='login-r-tabItem' />

                {/* <Input.Group compact className='login-r-tabItem'>
                  <Input size='large' maxLength='6' placeholder="请输入图形验证码" style={{ width: '60%' }} maxLength='6'/>
                  <Button  size='large' type="primary" style={{ width: '40%' }} >图片验证码</Button>
                </Input.Group> */}

                <Input.Group compact className='login-r-tabItem' size='large'>
                  <Input onChange={this.inputChange.bind(this, "smsCode")} style={{ width: '56%' }} placeholder="请输入短信验证码" maxLength='6' />
                  <Button disabled={this.state.smsValue != "获取验证码"} className="loginbtn" size='large' type="primary" style={{ width: '44%' }} onClick={this.getSmsCode.bind(this)}>
                    {this.state.smsValue}
                  </Button>
                </Input.Group>

                <Button type="primary" size='large' className='login-r-tabItem btn loginbtn' onClick={this.submit.bind(this)}>登录/注册</Button>
                {/* <Button type="primary" size='large' className='login-r-tabItem btn loginbtn' onClick={this.submit.bind(this)}>登录/注册</Button> */} 
                {/* todo */}

                <div className="login-r-footer">
                  未注册用户验证后自动进行注册，注册即代表同意
                    {/* <a href="https://www.zhitoubide.com/memberRight.html" target='_blank'>《使用许可协议》</a> */}
                  <a href="https://www.zhitoubide.com/agreement.html" target='_blank'>《用户注册及使用APP隐私协议》</a>
                </div>
              </TabPane>

              <TabPane tab="密码登录" key="2">
                <Input value={this.state.phoneNum} onChange={this.inputChange.bind(this, "phoneNum")} size='large' placeholder="请输入手机号" maxLength='11' allowClear className='login-r-tabItem' />
                <Space direction="vertical" className='login-r-tabItem'>
                  <Input.Password value={this.state.password}  onChange={this.inputChange.bind(this, "password")} size='large' placeholder="请输入密码" />
                </Space>
                <Button type="primary" size='large' className='login-r-tabItem btn loginbtn' onClick={this.passwordLogin.bind(this)}>登录</Button>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 5 }}>
                  {/* <span>忘记密码？</span>
                  <span>注册账号</span> */}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
