import React  from 'react';
import {parseTime} from '../../../../../utils/index'

const Card2 = (props) => {
  return (
    <div className="nav-right-card2-wrap" onClick={() => props.handleJump(props.data.tender_id,props.data.id)}>
      <div className="nav-right-card2">
        <p className="nav-right-card2-title">
          <b className={props.data.status === '0' ? 'unread' : ''}>招标</b>
          <span>{props.data.related_project_info && props.data.related_project_info.tender_title || ''}</span>
        </p>
        <p className="nav-right-card2-con">{props.data.summary}</p>
        <ul>
          <li>来自<span>{props.data.message_title || ''}</span></li>
          <li>{parseTime(props.data.push_time * 1000,'{y}-{m}-{d}')}</li>
        </ul>
      </div>
    </div>
    
  )
}

export default Card2;