import React, { useState, useEffect, useRef }  from 'react';
import { getMsgList, setRead } from '../../../../api/user'
import history from '../../../../router/history';
import brush from '../../../../assets/img/nav/icon_empty@2x.png'

import Card1 from './component/Card1'
import Card2 from './component/Card2'
import './index.less';

import { Tabs,Button } from 'antd';
const { TabPane } = Tabs;

const MsgIndex = (props) => {
 
  const [activeTab, setActiveTab] = useState('1'); // 消息列表
  const [msgList, setMsgList] = useState([]); // 消息列表

  useEffect(() => {
    initList()
  }, [activeTab]);

  const initList = () => {
    getMsgList({ type: activeTab - 1 }).then(res => {
      if (res.error_no === 0) {
        setMsgList(res.data.messages)
      }
    })
  }

  const handleTabChange = (key) => {
    setActiveTab(key)
    setMsgList([])
  }

  const handleJump1 = (id) => { // 系统通知跳转
    setRead({ id }).then(res => {
      if (res.error_no === 0) {
        initList()
        props.initUnreadNum()
      }
    })
  }

  const handleJump2 = (id) => { // 关注更新跳转
    setRead({ id }).then(res => {
      history.push(`/empty/bid-detail/${id}`)
      if (res.error_no === 0) {
        initList()
        props.initUnreadNum()
      }
    })
  }

  const handleJump3 = (id,messageid) => { // 订阅消息跳转
    setRead({ id }).then(res => {
      history.push(`/empty/bid-detail/${id}/${messageid}`)
      if (res.error_no === 0) {
        initList()
        props.initUnreadNum()
      }
    })
  }

  // const operations = <Button icon={<img style={{width:15,marginRight:5}} src={brush}/>} onClick={emptyRead}>清空未读</Button>;
  const handleClearAll = () => { // 清空全部
    setRead().then(res => {
      if (res.error_no === 0) {
        initList()
        props.initUnreadNum()
      }
    })
  }

  // const operations = <Button style={{ marginRight: 25 }} icon={<img style={{width:15,marginRight:5}} src={brush}/>} onClick={handleClearAll}>清空未读</Button>;
  const operations = <Button style={{ marginLeft: -20,border:'none' }} icon={<img style={{width:15,marginRight:5}} src={brush}/>} onClick={handleClearAll}>清空未读</Button>;
  return (
    <div className="nav-right-msg">
      <Tabs
        defaultActiveKey="1"
        onChange={handleTabChange}
        tabBarExtraContent={operations}>
        <TabPane tab="系统通知" key="1">
          {/* todo */}
        {/* {msgList.length && msgList.map((value, index) => <Link key="4" to={"/message-detail/"+value.id}><Card1 key={index} data={value} {...props} /></Link>) || <p className="no-data">暂无系统通知</p>} */}
          {msgList.length && msgList.map((value, index) => <Card1 handleJump={handleJump1} key={index} data={value} {...props} />) || <p className="no-data">暂无系统通知</p>}
        </TabPane>
        <TabPane tab="关注更新" key="2">
          {msgList.length && msgList.map((value, index) => <Card2 handleJump={handleJump2} key={index} data={value} {...props} />) || <p className="no-data">暂无关注更新</p>}
        </TabPane>
        <TabPane tab="订阅消息" key="3">
          {msgList.length && msgList.map((value, index) => <Card2 handleJump={handleJump3} key={index} data={value} {...props} />) || <p className="no-data">暂无订阅消息</p>}
        </TabPane>
      </Tabs>
    </div >
  )
}

export default MsgIndex;