import React from 'react';
import logo from '../../../assets/img/logo.png'
import User from '../Nav_right/User'
import Code from '../Nav_right/Code'
import Msg from '../Nav_right/Msg'
export const NavDataSource = {
    wrapper: { className: 'header home-page-wrapper kdfllcy10k-editor_css' }, //!!!del
    page: { className: 'home-page' },
    logo: {
        className: 'header-logo',
        children: logo,
    },
    Menu: {
        className: 'header-menu',
        children: [
            {
                name: 'item0',
                id:"item0",
                className: 'header-item',
                children: {
                    to: '/',
                    children: [
                        {
                            children: (
                                <span>
                                    <p>首页</p>
                                </span>
                            ),
                            name: 'text',
                        },
                    ],
                }
            },
            {
                name: 'search',
                className: 'header-item',
                children: {
                    to: '/search',
                    children: [
                        {
                            children: (
                                <span>
                                    <p>招标搜索</p>
                                </span>
                            ),
                            name: 'text',
                        },
                    ],
                },
            },
            {
                name: 'subscribe',
                className: 'header-item',
                children: {
                    to: '/subscribe',
                    children: [
                        {
                            children: (
                                <span>
                                    <p>招标订阅</p>
                                </span>
                            ),
                            name: 'text',
                        },
                    ],
                },
            },
            { 
                name: 'attention',
                className: 'header-item',
                children: {
                    to: '/attention',
                    children: [
                        {
                            children: (
                                <span>
                                    <p>我的关注</p>
                                </span>
                            ),
                            name: 'text',
                        },
                    ],
                },
            },
            { 
                name: 'guide',
                className: 'header-item',
                children: {
                    to: '/guide',
                    children: [
                        {
                            children: (
                                <span>
                                    <p>专家指导</p>
                                </span>
                            ),
                            name: 'text',
                        },
                    ],
                },
            },
        ],
    },
    rightIcon: {
        className: 'header-icon',
        children: [{
            name: 'item0',
            className: 'header-icon-message',
            children: {
                to: '/',
                className:"header-icon-message-a",
                children: [
                    {
                        children: (
                           <Msg />
                        ),
                        name: 'text',
                    },
                ],
            }
        },{
            name: 'item1',
            className: 'header-icon-user',
            children: {
                to: '/',
                className:"header-icon-user-a",
                children: [
                    {
                        children: (
                            <User />
                        ),
                        name: 'text',
                    },
                ],
            }
        },{
            name: 'item2',
            className: 'header-icon-more',
            children: {
                to: '/',
                className:"header-icon-more-a",
                children: [
                    {
                        children: (
                           <Code />
                        ),
                        name: 'text',
                    },
                ],
            }
        }]
    },
    mobileMenu: { className: 'header-mobile-menu' },
};
