import React from "react";
import gzhImg from "../../../assets/img/official.jpg";
import xcxImg from "../../../assets/img/mp2.jpg";

export const FooterDataSource = {
  wrapper: { className: "home-page-wrapper footer-wrapper" },
  OverPack: { className: "footer", playScale: 0.1 }, //首页之外的其他页面设置0.2 footer才出现 TODO!!!
  block: {
    className: "home-page",
    gutter: 0,
    children: [
      {
        name: "block1",
        xs: 24,
        md: 6,
        className: "block block1",
        title: {
          children: <span>{/* <p>三库一云</p> */}</span>,
        },
        childWrapper: {
          children: [
            {
              name: "link0",
              className: "footer-msg",
              children: (
                <span>
                  <p>北京智投必得科技有限公司</p>
                </span>
              ),
            },
            {
              name: "link1",
              className: "footer-msg",
              children: (
                <span>
                  <p>地址：北京市海淀区中关村大街18号8层</p>
                </span>
              ),
            },
            {
              name: "link2",
              className: "footer-msg",
              children: (
                <span>
                  <p>Email:zhitoubide@163.com</p>
                </span>
              ),
            },
            {
              name: "link3",
              className: "footer-msg",
              children: (
                <span>
                    <span>©</span>
                    <span>京ICP备2020048326号&nbsp; &nbsp; &nbsp; &nbsp;</span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block3",
        desc:"微信扫一扫进入小程序/公众号，更快掌握商机",
        // xs: 24,
        // md: 6,
        className: "block block3",
        title: {
          children: (
            <span>
              <p>微信小程序</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
                name: "image",
                className: "code-img",
                children: xcxImg,
              },
          ],
        },
      },
      {
        name: "block4",
        // xs: 24,
        // md: 6,
        className: "block",
        title: {
          children: (
            <span>
              <p>微信公众号</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: "image",
              className: "code-img",
              children: gzhImg,
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        <span>©</span>
        <span>浙ICP备19043834号-1&nbsp; &nbsp; &nbsp; &nbsp;</span>
        浙江益兴劳务服务有限公司
      </span>
    ),
  },
};
