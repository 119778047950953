import React, { useState, useEffect } from 'react';
import { Modal, Input,Button,Message } from 'antd';
import './index.less'
import { setpassword } from '../../../api/password'

const SetPassword = props => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [isTipsVisible, setIsTipsVisible] = useState(false);
    const [ pwd1, setPwd1] = useState("")
    const [ pwd2, setPwd2] = useState("")
    const tipsMsg = ['两次密码不一致','最少输入6位密码']
    const [ tipMsg, settipMsg] = useState(tipsMsg[0])
    const [ isBtnDisabled, setIsBtnDisabled] = useState(true)

    const showModal = () => {
        setIsModalVisible(true)
    }
    const handleOk = () => {
        if(pwd1==""){
            settipMsg(tipsMsg[1])
            setIsTipsVisible(true)
        } else if(pwd1==pwd2){
            console.log("提交")
            setpassword({
                password:pwd1,
                confirm_password:pwd2
            }).then(res=>{
                if(props.type=="change"){
                    // 提示密码修改成功
                    Message.success({ content: '密码修改成功!', duration: 2 });
                    props.handleDisplay(false)
                } else {
                    window.location.reload()
                }
                console.log(100099999,"密码修改完了",res)
            })
            // submitiPassword().then(res=>{
            //     setIsModalVisible(false);
            //     message.success({ content: '密码设置成功成功!', duration: 2 });
            // })
        } else {
            console.log("不能提交")
        }
        console.log(pwd1,pwd2,pwd1==pwd2)
        console.log("OK")
    };
    const changeValue = (value,index)=>{
        index==1?setPwd1(value):setPwd2(value)
        console.log(value)
        if(value.length < 6){
            setIsTipsVisible(true)
            settipMsg(tipsMsg[1])
            return false;
        } else {
            settipMsg(tipsMsg[0])
        }
        console.log(value < 6,value)
        if(index==1){
            
            if(pwd2 == value && value.length >=6){
                setIsTipsVisible(false)
                setIsBtnDisabled(false)
            } else {
                setIsTipsVisible(true)
            }
        } else {
            
            if(pwd1 == value && value.length >=6){
                setIsTipsVisible(false)
                setIsBtnDisabled(false)
            } else {
                setIsTipsVisible(true)
            }
        }
    }
    const handleCancel = () => {
        console.log("Cancel")
        setIsModalVisible(false);
        if(props.type!="change"){
            window.location.reload()
        }
    }
    
    return (
        <div className="mask">
                <Modal
                title={props.type=="change"?'修改密码':"请设置登录密码"}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                keyboard={false}
                maskClosable={false}
                cancelText={props.type=="change"?'取消':"跳过设置"}
                okButtonProps={res=><Button type="primary">确认</Button>}
                // cancelButtonProps={res=><Button>跳过设置</Button>}
                // okButtonProps={res=><Button type="primary" disabled={isBtnDisabled} className={isBtnDisabled?"a":"b"}>确认</Button>}
                // cancelButtonProps={res=><Button className="none" key="2">跳过设置</Button>}
                >
                    <p>密码长度在6—16位之间，包含字母，数字，区分大小写，不包含特殊字符</p>
                    {/* 发送验证码 */}
                    <p><Input.Password
                        value={pwd1}
                        onChange={(e)=>{changeValue(e.target.value,1)}}
                     placeholder="请输入密码" /></p>
                    <p><Input.Password
                        value={pwd2}
                        onChange={(e)=>{changeValue(e.target.value,2)}}
                     placeholder="请确认密码" /></p>
                     {isTipsVisible && <p className="errorTip">*{tipMsg}</p>}
                     {/* ，新密码与原密码相同 */}
                </Modal>
            {/* <div className="set-content">
                123
            </div> */}
        </div>
    )
}

export default SetPassword