import React from 'react';
import {parseTime} from '../../../../../utils/index'

const Card1 = (props) => {
  return (
    <div className="nav-right-card1-wrap" onClick={() => props.handleJump(props.data.id)}>
      <div className="nav-right-card1">
        <ul className={props.data.status === '0' ? 'unread' : ''}>
          <li>{props.data.message_title}</li>
          <li>{parseTime(props.data.create_time * 1000,'{y}-{m}-{d}')}</li>
        </ul>
        <p>{props.data.message_content}</p>
      </div>
    </div>
  )
}

export default Card1;