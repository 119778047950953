import React, { useState } from 'react';
import { Link } from "react-router-dom"
import './index.less';

const followIcon = require('../../../../assets/img/icon_follow@1x.png');
const userIcon = require('../../../../assets/img/Icon_user@1x.png');
const orderIcon = require('../../../../assets/img/Icon_order@1x.png');
const updateIcon = require('../../../../assets/img/Icon_update@1x.png');
const setIcon = require('../../../../assets/img/Icon_menu_set@2x.png');

const imgStyle = [
  {
    background: `url(${require('../../../../assets/img/nav/icon_regular_member_bg.png')})`,
    backgroundRepeat: 'no-repeat'
  },
  {
    background: `url(${require('../../../../assets/img/nav/icon_silver_member_bg.png')})`,
    backgroundRepeat: 'no-repeat'
  },
  {
    background: `url(${require('../../../../assets/img/nav/icon_gold_member_bg.png')})`,
    backgroundRepeat: 'no-repeat'
  },
  {
    background: `url(${require('../../../../assets/img/nav/icon_diamond_member_bg.png')})`,
    backgroundRepeat: 'no-repeat'
  }
]

const list = [
  { icon: orderIcon, title: '我的订单', link: '/order' },
  { icon: updateIcon, title: '我的订阅', link: '/assent' },
  { icon: followIcon, title: '我的关注', link: '/attention' }, //todo
  { icon: userIcon, title: '个人信息', link: '/user/base' },
  { icon: setIcon, title: '设置', link: '/set' },
];

const UserIndex = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  
  return (
    <div className="nav-right-user">
      <div className={`nav-right-user-title nav-right-user-${userInfo.level}`} style={imgStyle[userInfo.level]}>
        <p style={{fontWeight:800}}>{userInfo.user_name}</p>
        <ol>
          <li>畅享精准招标讯息</li>
          {/* {userInfo.level !== '0' && <li>续费</li> || null} */}
        </ol>
      </div>
      <ul>
        {list.map((value, index) => (
          <li key={index}>
            <img src={value.icon} alt=""/>
            <Link to={value.link}>{value.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default UserIndex;