import React from 'react';
import './index.less';
const officialImg = require('../../../../assets/img/official.jpg');
const mplImg = require('../../../../assets/img/mp2.jpg');
const list = [
  { title: '公众号', url: officialImg },
  { title: '小程序', url: mplImg },
];

const CodeIndex = (props) => {
  return (
    <div className="nav-right-code">
      <p>扫一扫进入小程序/公众号。更快掌握商机</p>
      <ul>
        {list.map((val, idx) => (
          <li key={idx}>
            <img src={val.url} alt=""/>
            <p>{val.title}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CodeIndex;