/*
 * @Author: your name
 * @Date: 2020-12-29 22:19:31
 * @LastEditTime: 2021-01-26 23:15:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /bid-ow/src/api/user.js
 */
import request from "../utils/request";
import url from "./url";

// 设置用户信息到localstorage
export function setUserInfoToLocal(res) {
  console.log("setUserInfoToLocal", res);
  if( JSON.stringify(res.data) != "[]" && JSON.stringify(res.data) != "{}"){
    localStorage.setItem("userInfo", JSON.stringify(res.data));
  }
  // if (res.error_no != 10001 || res.error_no === 11012403 && !localStorage.getItem("userInfo")) {
  //   setTimeout(() => {
  //     // window.location.reload();
  //   }, 500);
  // }
  // var a = JSON.parse(localStorage.getItem("userInfo"));
  // a.level = 3;
  // localStorage.setItem("userInfo", JSON.stringify(a));
}

// 获取用户详情
export function getUserInfo(params) {
  return request({
    url: url.getUserInfo,
    method: "GET",
    data: params,
  });
}

// 保存用户详情
export function saveUserInfo(params) {
  return request({
    url: url.saveUserInfo,
    method: "POST",
    data: params,
  });
}

// 消息列表
export function getMsgList(params) {
  return request({
    url: url.getMsgList,
    method: "GET",
    data: params,
  });
}

// 未读数量
export function getUnread() {
  return request({
    url: url.getUnread,
    method: "GET",
  });
}

// 设置为已读
export function setRead(params) {
  return request({
    url: url.setRead,
    method: "GET",
    data: params,
  });
}

// 消息详情
export function getMsgDetail(params) {
  return request({
    url: url.getMsgDetail,
    method: "GET",
    data: params,
  });
}

// 获取个人配置
export function getSetting() {
  return request({
    url: url.getSetting,
    method: "GET",
  });
}

// 保存个人配置
export function setSetting(params) {
  return request({
    url: url.setSetting,
    method: "POST",
    data: params,
  });
}

// 获取我的关注列表
export function getMyfollowList(params) {
  return request({
    url: url.getMyfollowList,
    method: "post",
    data: params,
  });
}

// 获取行业列表
export function getIndustryList(params) {
  return request({
    url: url.getIndustryList,
    method: "get",
  });
}
