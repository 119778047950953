import React from 'react';
// import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import { getChildrenToRender, isImg } from '../../../utils';
import './footer.less';
import { FooterDataSource } from './data'

class Footer extends React.Component {
  static defaultProps = {
    className: 'footer',
  };

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { desc, title, childWrapper, ...itemProps } = item;
      const style = {
        position:'absolute',
        left:30,
        top:-30,
        width:500,
        fontSize:14
      }
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null} style={{position:'relative'}}>
          
            {desc ? (
              <h2 className='abc' style={style}>
              {desc}
              </h2>
            ) : (
              null
            )}
          
          <div {...childWrapper}>
            {childWrapper.children.map(getChildrenToRender)}
          </div>
          <h2 style={{textAlign:'center',fontSize:15}} {...title}>
            {typeof title.children === 'string' &&
            title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const dataSource = FooterDataSource;
    const childrenToRender = this.getLiChildren(dataSource.block.children);
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...dataSource.block}
          >
            {childrenToRender}
          </QueueAnim>
          {/* <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
              </div>
            </div>
          </TweenOne> */}
        </OverPack>
      </div>
    );
  }
}

export default Footer;
