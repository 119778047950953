/*
 * @Author: your name
 * @Date: 2020-12-30 22:35:10
 * @LastEditTime: 2020-12-30 23:48:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /bid-ow/src/api/login.js
 */
import request from '../utils/request'
import url from './url'

// 获取短信验证码
export function getSmsCode(params) {
    return request({
        url: url.getSmsCode,
        method: 'post',
        data: params
    })
}
// 登陆、注册
export function goLogin(params) {
    return request({
        url: url.goLogin,
        method: 'post',
        data: params
    })
}

// 退出登录
export function outLogin(params) {
    return request({
        url: url.outLogin,
        method: 'get',
        data: params
    })
}

