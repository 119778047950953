/*
 * @Author: your name
 * @Date: 2020-12-29 22:08:37
 * @LastEditTime: 2021-01-09 21:30:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /bid-ow/src/utils/request.js
 */
import { message } from 'antd';

const request = (url, config,_data) => {
    let data = _data
    // console.log("Before request 入参：：",url,config)
    return fetch(url, config).then((res) => {
        if (!res.ok) {
            // 服务器异常返回
            throw Error('');
        }
        return res.json();
    }).then((res) => {
        if(data && data.noLogin){
            return res
        } else if(res.error_no === 10001 || res.error_no === 11012403){
            localStorage.clear()
            // localStorage.removeItem("token")
            setTimeout(() => {
                window.location.href = '/' 
            }, 1500);
            console.log(res.error_no === 11012403)
            console.table({url:url,res:res})
        } else if (res.error_no !== 0 && res.error_no !== 499 ) {
            // 0成功
            // 499 短信验证码未过期
            if (res.error_no !== 404) {
                message.error({ content: res.error_message, duration: 5 })
            }
            console.table({url:url,res:res})
            // 项目内部认为的错误
            // throw Error(res.error_no);
            return Promise.reject(res)
        } else {
            return res;
        }
    }).catch((error) => {
        console.log("ERROR",error)
        // 公共错误处理
        // message.error('内部错误:'+error.error_message);
        return Promise.reject(error)
    });
};

function format_data (data = {}, method) {
    data.token = localStorage.getItem("token") || ""
    data.platform = 1
    var params;
    if(method === "GET"){
        if (Object.keys(data).length) {
            params = "?"
            for(let key in data){
                params+=key+"="+data[key]+"&"
            }
            // console.log(params)
            return params
        }
       
    }
    params = new URLSearchParams()
    for (let key in data) {
        var value = data[key]
        if (value !== null && value !== undefined) {
            if (value instanceof Object || value instanceof Array) {
                value = JSON.stringify(value)
            }
            params.append(key, value)
        }
    }
    return params
}

export default (event) => {
    const { url, data, method } = event
    // console.log("REQUEST 入参：",event,format_data(data))
    
    const upperMethod = method.toUpperCase()
    var body = {}
    var requestUrl = url
    if(upperMethod === "POST"){
        body = {body:format_data(data, upperMethod),data:data}
    } else if(upperMethod === "GET"){
        requestUrl += format_data(data, upperMethod)
    }
    return request(requestUrl, {
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        },
        method: upperMethod || 'POST',
        ...body
    },data);
};
