/*
 * @Author: your name
 * @Date: 2020-12-29 22:08:37
 * @LastEditTime: 2021-01-26 23:00:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /bid-ow/src/api/url.js
 */
console.log("process.env!?!?!?!?", process.env.NODE_ENV);
// "development"
// "production"
// let tender =
//   process.env.NODE_ENV == "development" //todo
//     ? "http://master.zt-tender.zt-dev.zhitoubide.com"
//     : "https://tender.zhitoubide.com";
// let account =
//   process.env.NODE_ENV == "development" //todo
//     ? "http://master.zt-account.zt-dev.zhitoubide.com"
//     : "https://account.zhitoubide.com";


let tender =
  window.location.host != 'www.zhitoubide.com' //todo
    ? "http://master.zt-tender.zt-dev.zhitoubide.com"
    : "https://tender.zhitoubide.com";
let account =
  window.location.host != 'www.zhitoubide.com' //todo
    ? "http://master.zt-account.zt-dev.zhitoubide.com"
    : "https://account.zhitoubide.com";



console.log(tender, account);
const url = {
  // 下拉配置
  getSelectConfig: tender + "/project/config", // 获取下拉配置
  getBankConfig: tender + "/config/bank", // 获取银行 配置

  // 获取城市
  getCity: tender + "/project/getcitycodev2",
  // 登录
  getSmsCode: account + "/phone/loginsms", //获取短信验证码
  goLogin: account + "/account/phonelogin", //登录注册
  outLogin: account + "/account/loginout", // 退出登录

  // 密码
  // setpassword:'http://lxh.zt-account.zt-dev.zhitoubide.com/' + "account/setpassword", //todo!
  setpassword:account + "/account/setpassword",
  // passwordlogin:"http://lxh.zt-account.zt-dev.zhitoubide.com/" + "account/passwordlogin",
  passwordlogin:account + "/account/passwordlogin",
  

  // 首页
  getLevelConfig: account + "/level/config", // 获取会员列表配置
  createOrder: tender + "/order/create", //创建订单

  // 招标搜索

  // 获取搜索结果

  // http://master.zt-tender.zt-dev.geemii.com/project/list

  getSearchList: tender + "/project/search",
  getSearchDetail: tender + "/project/detail",

  // 招标订阅
  getSubscribeConfig: tender + "/subscribe/conf", //会员等级对应的订阅量
  createSubscribe: tender + "/subscribe/create",
  getKeywordList: tender + "/keyword/list",
  getSubscribeList: tender + "/subscribe/list", // 获取订阅列表
  getMySubscribeList: tender + "/subscribe/myprojectinfo", //获取我的订阅列表
  delSubscribe: tender + "/subscribe/offline", // 删除订阅

  // 我的关注
  getMyfollowList: tender + "/project/myfollow",
  setMyfollow: tender + "/project/follow",

  // 个人中心
  getUserInfo: account + "/user/info", // 用户信息
  saveUserInfo: account + "/user/updateinfo", // 编辑用户信息
  getMsgList: tender + "/message/list", // 消息通知
  getMsgDetail: tender + "/message/detail", // 消息通知
  getUnread: tender + "/message/unread", // 未读数据
  setRead: tender + "/message/read", // 未读处理成已读

  getSetting: account + "/user/getsetting", // 获取个人配置
  setSetting: account + "/user/setsetting", // 保存个人配置
  getIndustryList: account + "/user/industrylist", // 获取推荐行业列表

  // 订单
  getOrderList: tender + "/order/list", // 我的订单列表
  cancelOrder: tender + "/order/cancel", // 取消订单
  getOrderDetail: tender + "/order/info", // 订单详情
  setOrderPay: tender + "/order/pay", // 订单支付
  uploadReceipt: tender + "/order/uploadreceipt", // 上传凭证

  // 上传图片
  setUpload: tender + "/util/upload",
};
export default url;
