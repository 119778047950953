import request from '../utils/request'
import url from './url'


// 
export function setpassword(params) {
    return request({
        url: url.setpassword,
        method: 'post',
        data: params
    })
}

export function passwordlogin(params) {
    return request({
        url: url.passwordlogin,
        method: 'post',
        data: params
    })
}
