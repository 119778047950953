/*
 * @Author: your name
 * @Date: 2020-12-23 20:47:34
 * @LastEditTime: 2021-01-03 15:35:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /bid-ow/src/router/index.js
 */

import AsyncComponent from './asyncComponent'
const home = AsyncComponent(() => import("../pages/home"))
const search = AsyncComponent(() => import("../pages/search"))
const guide = AsyncComponent(() => import("../pages/guide"))
const bidDetail = AsyncComponent(() => import("../pages/bidDetail"))
const empty = AsyncComponent(() => import("../pages/empty"))
const subscribe = AsyncComponent(() => import("../pages/subscribe"))
const attention = AsyncComponent(() => import("../pages/attention"))
const order = AsyncComponent(() => import("../pages/order"))
const assent = AsyncComponent(() => import("../pages/assent"))
const set = AsyncComponent(() => import("../pages/set"))
const user = AsyncComponent(() => import("../pages/user"))
const pay = AsyncComponent(() => import("../pages/pay"))
const messageDetail = AsyncComponent(() => import("../pages/messageDetail"))

// import home from "../pages/home"
// import search from "../pages/search"
// import bidDetail from "../pages/bidDetail"
// import subscribe from "../pages/subscribe"
// import attention from "../pages/attention"
// import order from "../pages/order"
// import assent from "../pages/assent"
// import set from "../pages/set"
// import user from "../pages/user"

export default [
    {
        path: '/',
        name: '首页',
        component: home
    },
    {
        path: '/member',
        name: '首页',
        component: home
    },
    {
        path: '/bid-detail/:id',
        name: '招标详情',
        component: bidDetail
    },
    {
        path: '/bid-detail/:id/:messageid',
        name: '招标详情',
        component: bidDetail
    },
    {
        path: '/empty/:router/:id',
        name: '中转',
        component: empty
    },
    {
        path: '/empty/:router/:id/:messageid',
        name: '中转',
        component: empty
    },
    {
        path: '/message-detail/:id',
        name: '招标详情',
        component: messageDetail
    },
    
    {
        path: '/guide',
        name: '专家指导',
        component: guide
    },
    
    {
        path: '/search',
        name: '招标搜索',
        component: search
    },
    {
        path: '/subscribe',
        name: '招标订阅',
        component: subscribe
    },
    {
        path: '/attention',
        name: '我的关注',
        component: attention,
        position:"我的关注"
    },
    {
        path: '/order',
        name: '我的订单',
        component: order,
        position:"我的订单"
    },
    {
        path: '/assent',
        name: '我的订阅',
        component: assent,
        position:"我的订阅"
    },
    {
        path: '/user/:id',
        name: '个人信息',
        component: user,
        position:"个人信息"
    },
    {
        path: '/set',
        name: '设置',
        component: set,
        position:"设置"
    },
    {
        path: '/pay/:id',
        name: '支付',
        component: pay,
        position:"支付"
    },

    // {
    //     path: '/about',
    //     name: 'about',
    //     component: about
    // },
    // {
    //     path: '/expertCloud',
    //     name: 'expertCloud',
    //     component: expertCloud
    // },
    // {
    //     path: '/personnelProduct',
    //     name: 'personnelProduct',
    //     component: personnelProduct
    // },
    // {
    //     path: '/taxProduct',
    //     name: 'taxProduct',
    //     component: taxProduct
    // },
    // {
    //     path: '/valueAddedServices',
    //     name: 'valueAddedServices',
    //     component: valueAddedServices
    // }
]

