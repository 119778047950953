import React from 'react';
import Nav from './components/Nav';
import Footer from './components/Footer';
import './style.less';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { BackTop } from 'antd';
// import Position from '../components/position'
// import getPosition from '../utils/getposition'
import Login from '../components/login'
import SetPassword from '../components/password/set/index'
import { getSelectConfig, getCity } from '../api/config'
import { getUserInfo, setUserInfoToLocal } from '../api/user'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN';

import routesList from '../router'
window.routesList = routesList

let isMobile;


const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class ROUTER extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
      showLogin: false, //显示隐藏登录窗口
      showSetPassword:false, //显示隐藏设置密码窗口
    };
  }

  componentDidMount() {
    getUserInfo({noLogin:true}).then(res => {
      console.log(119228337446,res)
      res && setUserInfoToLocal(res)
    },res=>{
      console.log(911,res)
    })
    // getUserInfo().then(res => {
    //   if (res.error_no === 0) {
    //     setUserInfoToLocal(res)
    //   }
    // })
    getSelectConfig().then(res => {
      let tenderTypeArr = [];
      let tenderTypeObj = res.data.project.tender_type
      for (let oName in tenderTypeObj) {
        let item = { name: tenderTypeObj[oName], key: oName }
        tenderTypeArr.push(item)
      }
      localStorage.setItem("tenderTypeArr",JSON.stringify(tenderTypeArr))
      // console.log(2929,res.data.project.tender_type,tenderTypeArr)
      localStorage.setItem("selectConfig", JSON.stringify(res.data.project))
    })
    getCity().then(res => {
      localStorage.setItem("cityList", JSON.stringify(res.data))
    })

  }

  handleDisplay = (flag,token) => {
    console.log(100111999,{flag,token})
    if(token){
      this.setState({
        showSetPassword:true
      })
      getUserInfo().then(res => {
        setUserInfoToLocal(res)
        console.log(1122121,this)
        // window.location.href = '/#/empty/index'
        
      })
    }
    console.log(12008,flag,token)
    this.setState({
      showLogin: flag,
    });
  }

  reload = ()=>{
    setTimeout(()=>{
      location.reload()
    },  100)
  }

  handlePasswordDisplay = flag => {
    this.setState({
      showSetPassword: flag,
    });
  }
  changeR(r){
    console.log(r,"?!?!?!??!?!?")
  }

  render() {
    const children =
      <Router>
        <Nav
          id="Nav"
          key="Nav"
          isMobile={this.state.isMobile}
          handleDisplay={this.handleDisplay}
        />
        <div className="content">
          {/* 位置导航 */}
          {/* {getPosition().position ? <Position
            id="Position"
            key="Position"
            dataSource={getPosition()} /> : null} */}

          <Switch>
            {
              routesList.map((item, index) => {
                return <Route exact path={item.path} key={index} component={item.component} onRouteChange={this.changeR}/>
              })
            }
            <Redirect to="/" />
          </Switch>
        </div>
        <Footer
          id="Footer"
          key="Footer"
        />
        <BackTop />
      </Router>;

    return (
      <ConfigProvider locale={zhCN}>
        <div
          className="templates-wrapper"
          ref={(d) => {
            this.dom = d;
          }}
        >
          {children}
          {this.state.showLogin && <Login handleDisplay={this.handleDisplay} />}
          {this.state.showSetPassword && <SetPassword handleDisplay={this.handlePasswordDisplay} />}
        </div>
      </ConfigProvider>
    );
  }
}
