import React from 'react';
import TweenOne from 'rc-tween-one';
import { Button, Menu, Message,Modal } from 'antd';
import { getChildrenToRender } from '../../../utils';
import { getUnread } from '../../../api/user'

import './nav.less';
import { Link, withRouter } from "react-router-dom"
import { NavDataSource } from './data'
import logo from '../../../assets/img/_home/icon_menu_pic_other.png'
import logoIndex from '../../../assets/img/_home/icon_menu_pic.png'
import imgtest from '../../../assets/img/_home/pic_experts.png'

import User from '../Nav_right/User'
import Code from '../Nav_right/Code'
import Msg from '../Nav_right/Msg'

// import { UserDeleteOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Item, SubMenu } = Menu;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      isIndex: true,
      currentRoute:"/",
      unreadNum: 0
    };
  }

  isIndex(bol) {

  }

 

  goIndex(){
    window.location.href = '/#/'
  }
  needLogin(){
    if(!localStorage.getItem('token')){
      Modal.confirm({
        title: '提示',
        icon: <ExclamationCircleOutlined />,
        content: '请先登录',
        okText: '去登录',
        cancelText: '取消',
        onCancel:()=>{return false},
        cancelButtonProps: {
          disabled: true,
        },
        onOk:this.goIndex
      });
    }
  }
  componentDidMount() {
    if (localStorage.getItem('token'))  { // 请求未读接口
      this.initUnreadNum()
    }
    if(this.props.location.pathname == '/'){
      setTimeout(()=>{
        console.log(119119119,this.props.location.pathname)
        document.getElementById('item0').className = document.getElementById('item0').className + " ant-menu-item-selected"
      },500)
    } else {
      console.log(120120120,document.getElementById('item0').className)
    }
    if (this.props.location.pathname != '/' && this.state.isIndex == true) { //页面刷新的时候判断
      if (this.props.location.pathname != '/search'){
        this.needLogin()
      }
      this.setState({
        isIndex: false,
        currentRoute:this.props.location.pathname.slice(1,this.props.location.pathname.length)
      })
      console.log("mount---isIndex:不是不是首页", this.props.location.pathname)
    }
  }
  componentDidUpdate(prevProps) {
    if ((prevProps.isMobile !== this.props.isMobile) && localStorage.getItem('token')) {  // 请求未读接口
      this.initUnreadNum()
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname != '/') { //页面刷新的时候判断
        this.setState({
          isIndex: false
        })
        if (this.props.location.pathname != "/search" && !localStorage.getItem("token")) {
          this.needLogin()
          Message.error({ content: '请先登录', duration: 3 })
        }
      } else {
        this.setState({
          isIndex: true
        })
      }
    }

    if(this.props.location.pathname != '/'){
      if(document.getElementById('item0').className.indexOf('ant-menu-item-selected')>=0){
        setTimeout(()=>{
          console.log(110110110,document.getElementById('item0').className)
          document.getElementById('item0').className = document.getElementById('item0').className.replace(" ant-menu-item-selected",'')
        },200)
      }
    } 
    console.log("componentDidUpdate---isIndex:是首页???-------------------------------", this.state.isIndex, prevProps.location.pathname, this.props.location.pathname)
  }

  initUnreadNum = () => {
    getUnread().then(res => {
      this.setState({
        unreadNum: res.data.un_read_count
      })
    })
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };
  handleClick = ({ item, key, keyPath, domEvent }) => {
    // debugger;
    console.log(item.node.className)
    item.node.className = item.node.className.replace("ant-menu-item-active","")
    let url = item.props.children[1].props.url || '/'
    console.group()
    console.log(item.props.children)
    console.log(item)
    console.log(key)
    console.log(keyPath)
    console.log(domEvent)
    console.groupEnd()
    return false;
  };
  render() {
    const { isMobile, ...props } = this.props;
    const dataSource = NavDataSource
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      // console.log(102030,itemProps)
      if(!localStorage.getItem('token')){
        if(itemProps.name == "item2" || itemProps.name == "item3")
        itemProps.disabled = true
        // itemProps.selectable = false
        
        itemProps.title ="请登录后在使用此功能"
        itemProps.onClick = ()=>{
          console.log(1111111111111111111111111111111)
          return false;
        }

      }
      if (subItem) {
        // return (
        //   <SubMenu
        //     key={item.name}
        //     {...itemProps}
        //     title={
        //       <div
        //         {...a}
        //         className={`header-item-block ${a.className}`.trim()}
        //       >
        //         {a.children.map(getChildrenToRender)}
        //       </div>
        //     }
        //     popupClassName="header-item-child"
        //   >
        //     {subItem.map(($item, ii) => {
        //       const { children: childItem } = $item;
        //       const child = childItem.href ? (
        //         <a {...childItem}>
        //           {childItem.children.map(getChildrenToRender)}
        //         </a>
        //       ) : (
        //           <div {...childItem}>
        //             {childItem.children.map(getChildrenToRender)}
        //           </div>
        //         );
        //       return (
        //         <Item key={$item.name || ii.toString()} {...$item}>
        //           <Link to={$item.to || '/'}>
        //             {child}
        //           </Link>
        //         </Item>
        //       );
        //     })}
        //   </SubMenu>
        // );
      }
      return (
        <Item key={item.name} {...itemProps}>
          <Link {...a} className={`header-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </Link>
        </Item>
      );
    });
    const logoDom = this.state.isIndex ? { className: 'header-logo', children: logoIndex } : { className: 'header-logo', children: logo }


    const iconData = dataSource.rightIcon.children;
    const iconChildren = iconData.map((item) => {
      const { children: bb, ...itemProps } = item;
      return (
        <SubMenu
          className="ccccc"
          key={item.name}
          title={
            <div className={`${itemProps.className}-wrap`}>
              {(itemProps.className=== 'header-icon-message' && this.state.unreadNum) && <span>{this.state.unreadNum}</span> || null}
              <p className={itemProps.className} />
            </div>
          }
          popupClassName={`menu-${itemProps.className}`}
        >
          {/* {bb.children.map(getChildrenToRender)} */}
          {itemProps.className === 'header-icon-message' && <Msg initUnreadNum={this.initUnreadNum} />}
          {itemProps.className === 'header-icon-user' && <User />}
          {itemProps.className === 'header-icon-more' && <Code />}
        </SubMenu>
      );
    });


    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        // className={`${this.state.isIndex ? ' isHome' : ' notHome'}`}
        className={`${this.state.isIndex ? " isHome " : " notHome "}header home-page-wrapper kdfllcy10k-editor_css`}
      // {...props}
      >
        <div
          className=""
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}${this.state.isIndex ? ' isHome' : ' notHome'}`}
        >
          <TweenOne //logo
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...logoDom}
          // {...dataSource.logo}
          >
            <img width="100%" src={logoDom.children} alt="img" />
          </TweenOne>
          <TweenOne
            {...dataSource.rightIcon}
          >
            {/* ICON */}
            {localStorage.getItem('token') ? <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={['sub0']}
              onClick={this.handleClick}
            >
              {iconChildren}
            </Menu> : <Button className="loginBtn" type="primary" onClick={() => this.props.handleDisplay(true)}>注册/登录</Button>}
          </TweenOne>
          <TweenOne //导航栏
            {...dataSource.Menu}
            moment={moment}
            reverse={!!phoneOpen}
          >
            {/* NAV */}
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={[window.location.hash.slice(2,window.location.hash.length)]}

              onClick={this.handleClick}
            >
              {navChildren}
            </Menu>
          </TweenOne>

        </div>
      </TweenOne>
    );
  }
}

export default withRouter(Header);
